
function App() {
  return (
    <>



      <h1 className="text-4xl font-light">This is TheFinrev</h1>
      <h1 className="text-4xl font-semibold">This is TheFinrev</h1>
      <h1 className="text-4xl font-bold">This is TheFinrev</h1>
      <h1 className="text-4xl font-black">This is TheFinrev</h1>




    </>
  );
}

export default App;
